// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alumni-js": () => import("./../../../src/pages/alumni.js" /* webpackChunkName: "component---src-pages-alumni-js" */),
  "component---src-pages-benefits-js": () => import("./../../../src/pages/benefits.js" /* webpackChunkName: "component---src-pages-benefits-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marketingpr-js": () => import("./../../../src/pages/marketingpr.js" /* webpackChunkName: "component---src-pages-marketingpr-js" */),
  "component---src-pages-mentors-js": () => import("./../../../src/pages/mentors.js" /* webpackChunkName: "component---src-pages-mentors-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-playbook-js": () => import("./../../../src/pages/playbook.js" /* webpackChunkName: "component---src-pages-playbook-js" */),
  "component---src-pages-program-js": () => import("./../../../src/pages/program.js" /* webpackChunkName: "component---src-pages-program-js" */),
  "component---src-pages-residents-js": () => import("./../../../src/pages/residents.js" /* webpackChunkName: "component---src-pages-residents-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-benefit-js": () => import("./../../../src/templates/benefit.js" /* webpackChunkName: "component---src-templates-benefit-js" */),
  "component---src-templates-newsitem-js": () => import("./../../../src/templates/newsitem.js" /* webpackChunkName: "component---src-templates-newsitem-js" */),
  "component---src-templates-partner-js": () => import("./../../../src/templates/partner.js" /* webpackChunkName: "component---src-templates-partner-js" */),
  "component---src-templates-resident-js": () => import("./../../../src/templates/resident.js" /* webpackChunkName: "component---src-templates-resident-js" */)
}

